
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import  Yup from "@/bundle/validations/YupExpended";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const togglePassword = ref(true);

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("L'adresse e-mail"),
      password: Yup.string().min(4).required().label("Le mot de passe"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            router.push({name: 'dashboard'})
            router.afterEach((to, from, failure) => {
              window.location.reload();
              
            });
          })
          .catch(() => {
            Swal.fire({
              text: "E-mail ou mot de passe invalide",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Veuillez réessayer",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(function () {
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      togglePassword
    };
  },
});
